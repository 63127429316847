@import './mixin.scss';
@import './var.scss';


.accordion {
    background-color: #fff;
    padding: 1vw 1.5vw;
    box-shadow: 0px 0px 2px 2px $gris-claro;
    border-radius: 1vw;
}

.accordion_item {
    border-bottom: 1px solid $gris-claro;
}

.accordion_header {
    background-color: #fff;
    padding: 0.73vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: max-height 0.3s ease;
}

.accordion_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.accordion_item.active .accordion_content {
    max-height: 500px;
    /* Ajusta la altura máxima según tus necesidades */
}

.icon_acordion {
    transform: rotate(90deg);
    color: $gris-claro;
    transition: transform 0.3s ease;
    font-size: 1.5vw;
}

.icon_acordion_rotate {
    transform: rotate(270deg);
    color: $gris-claro;
    transition: transform 0.3s ease-out;
    font-size: 1.5vw;
}

.title_pregunta {
    width: 90%;
}

@media (max-width: 1200px) {
    .icon_acordion {

        font-size: 1.7rem;
    }

    .icon_acordion_rotate {
        font-size: 1.7rem;
    }
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .tama_flecha {
        font-size: 2.2rem;
    }
}

//only phone
@media (max-width: 576px) {
    .icon_acordion {
        font-size: 3.2rem;
    }

    .icon_acordion_rotate {
        font-size: 3.2rem;
    }

    .accordion {
        padding: 2rem 1rem;
        border-radius: 1vw;
    }

    .tama_flecha {
        font-size: 2.2rem;
    }
}